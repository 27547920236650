import React from "react";

function Icon({ className, w }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 375 1080 705"
            width={w + "rem"}
            height="auto"
            className=""
        >
            <path
                className={className + "w-full h-full"}
                d="M1080 1080H800.5L536.3 738.8 277.5 1080H0l534.5-704.8L1080 1080z"
            ></path>
        </svg>
    );
}

export default Icon;
