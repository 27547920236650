import React from 'react'
import GameGrid from '../../Components/Molecules/Grids/GameGrid'

function Games() {
  return (
    <div className='bg-background min-h-screen pt-24 text-bianco'>
        <h1 className='text-4xl text-center'>Games</h1>
        <GameGrid />
        
    </div>
  )
}

export default Games