import React, { useEffect, useState } from "react";
import {
	collection,
	query,
	where,
	onSnapshot,
	setDoc,
	doc,
	getDoc,
} from "firebase/firestore";
import { db } from "../../../firebase"; // Assicurati di importare correttamente db
import useUserStore from "../../../stores/userStore";
import useGameStore from "../../../stores/gameStore";
import GameModal from "../../../Components/Molecules/Modals/GameModal";
import { toast } from "sonner";
import axios from "axios";
import LoadButton from "../../../Components/Atoms/Buttons/LoadButton";

function Prefissi() {
	const [inputRoom, setInputRoom] = useState("");
	const { user } = useUserStore();
	const room = useGameStore((state) => state.room);
	const setRoom = useGameStore((state) => state.setRoom);
	const [word, setWord] = useState("");
	const [timeLeft, setTimeLeft] = useState(0);

	const getNextTurnIndex = (currentIndex, players) => {
		let nextIndex = (currentIndex + 1) % players.length;
		while (players[nextIndex].eliminated) {
			nextIndex = (nextIndex + 1) % players.length;
		}
		return nextIndex;
	};

	const isStillPlaying = async () => {
		const activePlayers = room.players.filter((player) => !player.eliminated);
		if (activePlayers.length === 1) {
			const winner = activePlayers[0];
			const docRef = doc(db, "prefissi", room.id);

			await setDoc(docRef, {
				...room,
				status: "finished",
				winner: winner.uid,
			});

			toast.success(`Partita terminata! Il vincitore è ${winner.name}`);
			return;
		}
	};

	useEffect(() => {
		if (!room.turnTimer || room.status !== "playing") return;

		const interval = setInterval(() => {
			const remaining = Math.max(
				0,
				Math.floor((room.turnTimer - Date.now()) / 1000)
			);
			setTimeLeft(remaining);

			if (remaining === 0) {
				clearInterval(interval);

				if (room.turn === user.uid) {
					const docRef = doc(db, "prefissi", room.id);

					const turnIndex = room.players.findIndex((p) => p.uid === room.turn);

					const nextTurnIndex = getNextTurnIndex(turnIndex, room.players);

					const updatedPlayers = room.players.map((player) =>
						player.uid === room.turn ? { ...player, eliminated: true } : player
					);

					const activePlayers = updatedPlayers.filter(
						(player) => !player.eliminated
					);

					if (activePlayers.length === 1) {
						setDoc(docRef, {
							...room,
							players: updatedPlayers,
							status: "finished",
							winner: activePlayers[0].uid,
						});

						toast.success(
							`Partita terminata! Il vincitore è ${activePlayers[0].name}`
						);
						return;
					}

					setDoc(docRef, {
						...room,
						players: updatedPlayers,
						turn: room.players[nextTurnIndex].uid,
						turnTimer: Date.now() + 30000,
					});
				}
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [room.turnTimer, room.turn, room.status, room.players, user.uid, room.id]);

	useEffect(() => {
		if (!room.id) {
			console.log("Room ID non presente");
			return;
		}

		const q = query(
			collection(db, "prefissi"),
			where("id", "==", room.id) // Filtra per l'ID della stanza
		);

		const unsubscribe = onSnapshot(q, (querySnapshot) => {
			querySnapshot.forEach((doc) => {
				const data = doc.data();
				console.log(data);

				setRoom(data); // Aggiorna lo stato della stanza per tutti i client
			});
		});

		return () => unsubscribe();
	}, [room.id, setRoom]);

	const handleCreateRoom = async () => {
		console.log("crea stanza");
		const stanza = Math.random().toString(36).substring(8).toUpperCase();
		await setDoc(doc(db, "prefissi", stanza), {
			id: stanza,
			status: "waiting",
			players: [{ uid: user.uid, name: user.name, points: 0 }],
			turn: null,
			usedWords: [],
			currentSuffix: null,
			createdBy: user.uid,
			createdAt: new Date(),
		});
		setRoom((await getDoc(doc(db, "prefissi", stanza))).data());
	};

	const handleJoinRoom = async () => {
		console.log("unisciti a stanza");
		const docRef = doc(db, "prefissi", inputRoom);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			const data = docSnap.data();
			const playerExists = data.players.some((p) => p.uid === user.uid);

			if (playerExists) {
				toast.error("Sei già nella stanza");
				setRoom(data); // Assicurati che lo stato locale venga aggiornato
				return;
			}

			if (data.status === "waiting") {
				// Aggiungi il nuovo giocatore
				const updatedData = {
					...data,
					players: [
						...data.players,
						{ uid: user.uid, name: user.name, points: 0 },
					],
				};

				await setDoc(docRef, updatedData); // Aggiorna Firestore
				setRoom(updatedData); // Aggiorna lo stato locale
			} else {
				toast.error("Partita in corso o terminata");
			}
		} else {
			toast.error("Stanza non trovata");
		}
	};

	const handleLeaveRoom = async () => {
		console.log("abbandona stanza");
		const docRef = doc(db, "prefissi", room.id);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			const data = docSnap.data();
			if (data.players) {
				const newPlayers = data.players.filter((p) => p.uid !== user.uid);
				if (newPlayers.length) {
					await setDoc(docRef, { ...data, players: newPlayers });
					setRoom({});
				} else {
					await setDoc(docRef, {});
					setRoom({});
				}
			} else {
				setRoom({});
				toast.error("Nessun giocatore trovato nella stanza");
			}
		} else {
			toast.error("Stanza non trovata");
		}
	};

	const handleDeleteRoom = async () => {
		console.log("elimina stanza");
		const docRef = doc(db, "prefissi", room.id);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			const data = docSnap.data();
			if (data.createdBy === user.uid) {
				await setDoc(docRef, { deleted: true });
				setRoom({});
			} else {
				toast.error("Solo il creatore della stanza può eliminarla");
			}
		} else {
			toast.error("Stanza non trovata");
		}
	};

	const handleRestartRoom = async () => {
		console.log("ricomincia stanza");
		const docRef = doc(db, "prefissi", room.id);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			const data = docSnap.data();
			if (data.createdBy === user.uid) {
				const updatedPlayers = data.players.map((player) => ({
					...player,
					eliminated: false,
				}));

				await setDoc(docRef, {
					...data,
					status: "waiting",
					usedWords: [],
					players: updatedPlayers,
				});
				setRoom(data);
				window.location.reload();
			} else {
				toast.error("Solo il creatore della stanza può ricominciare");
			}
		} else {
			toast.error("Stanza non trovata");
		}
	};

	const handleStartGame = async () => {
		console.log("inizia partita");
		try {
			const docRef = doc(db, "prefissi", room.id);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				const data = docSnap.data();
				if (data.createdBy === user.uid) {
					const randomTurn =
						data.players[Math.floor(Math.random() * data.players.length)].uid;
					data.turn = randomTurn;
					const randomPrefix = await axios.get(
						process.env.REACT_APP_API_URL + "/prefix"
					);

					await setDoc(docRef, {
						...data,
						status: "playing",
						currentSuffix: randomPrefix.data.prefix,
						turnTimer: Date.now() + 6000000000000000000, //60 000
					});
					setRoom(data);
					window.location.reload();
				} else {
					toast.error("Solo il creatore della stanza può iniziare la partita");
				}
			}
		} catch (error) {
			console.error(error);
			toast.error("Errore durante l'inizio della partita");
		}
	};

	const sendWord = async () => {
		if (!word.length) {
			toast.error("Inserisci una parola");
			return;
		}

		if (!word.startsWith(room.currentSuffix)) {
			toast.error("La parola non inizia con il prefisso corrente");
			return;
		}

		const docRef = doc(db, "prefissi", room.id);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			const data = docSnap.data();
			const player = data.players.find((p) => p.uid === user.uid);

			if (player) {
				const wordCheck = await axios.get(
					process.env.REACT_APP_API_URL + `/prefix/${word}`
				);

				if (wordCheck.data.error) {
					toast.error("Parola non trovata");
					return;
				}

				const isUsed = data.usedWords.some(
					(w) => w.word === wordCheck.data.word
				);
				if (isUsed) {
					toast.error("Parola già usata");
					return;
				}
				const sameLemma = data.usedWords.filter(
					(w) => w.lemma === wordCheck.data.lemma
				);
				const sameType = sameLemma.some((w) => w.type === wordCheck.data.type);
				if (sameType) {
					toast.error("Parola non valida");
					return;
				}

				const turnIndex = data.players.findIndex((p) => p.uid === user.uid);
				const nextTurnIndex = getNextTurnIndex(turnIndex, data.players);

				const activePlayers = data.players.filter(
					(player) => !player.eliminated
				);

				if (activePlayers.length === 1) {
					setDoc(docRef, {
						...data,
						status: "finished",
						winner: activePlayers[0].uid,
					});

					toast.success(
						`Partita terminata! Il vincitore è ${activePlayers[0].name}`
					);
					return;
				}

				await setDoc(docRef, {
					...data,
					usedWords: [...data.usedWords, wordCheck.data],
					turn: data.players[nextTurnIndex].uid,
					turnTimer: Date.now() + 60000,
				});

				setWord("");
			}
		} else {
			toast.error("Stanza non trovata");
		}
	};

	return (
		<div className="bg-background h-screen pt-28 text-bianco flex flex-col jusfitfy-center items-center">
			<div className="flex justify-center items-center w-full relative">
				<h1 className="text-4xl mx-auto">Prefix</h1>

				{room.id && (
					<div className="flex justify-end items-center gap-2 absolute right-4">
						<h2>Codice stanza: </h2>
						<h2 className="text-3xl tracking-widest">{room.id}</h2>
						<div className="h-8">
							{room.createdBy === user.uid ? (
								<LoadButton
									handleClick={handleDeleteRoom}
									head={"Elimina stanza"}
								/>
							) : (
								<LoadButton
									handleClick={handleLeaveRoom}
									head={"Abbandona stanza"}
								/>
							)}
						</div>
					</div>
				)}
			</div>
			<div className="">
				{room?.id?.length ? (
					<div className="flex flex-col items-center justify-items-center h-[75dvh] w-[100vw]">
						{room.status === "finished" ? (
							<div className="mt-10 text-center">
								<h2>Partita terminata!</h2>
								{room.winner !== user.uid ? (
									<p>
										Il vincitore è{" "}
										{room.players.find((p) => p.uid === room.winner)?.name}
									</p>
								) : (
									<p>Hai vinto la partita!</p>
								)}
								<LoadButton
									handleClick={handleRestartRoom}
									head={"Ricomincia"}
								/>
							</div>
						) : room.status === "waiting" ? (
							<div className="mt-10 text-center md:justify-self-start">
								<p>Giocatori: ({room.players.length}) </p>
								{room.players.map((player) => (
									<h3
										className={`${player.uid === user.uid ? "text-xl" : "text-lg"}`}
										key={player.uid}
									>
										{player.name}
									</h3>
								))}
								<div className="mt-20 flex flex-col gap-2">
									<LoadButton
										handleClick={handleStartGame}
										head={"Inizia partita"}
									/>
								</div>
							</div>
						) : (
							<div className="mt-10">
								<div className="text-center">
									<h2 className="text-2xl">Partita in corso</h2>
									<h3 className="">
										Prefisso:{" "}
										<span className="text-xl tracking-widest">
											{room.currentSuffix.toUpperCase()}
										</span>
									</h3>
								</div>


                                
								<p>Giocatori: ({room.players.length}) </p>
								{room.players.map((player) => {
									return (
										<div
											key={player.uid}
											className={`flex items-center gap-2 ${
												player.eliminated ? "opacity-50" : ""
											}`}
										>
											{player.uid === room.turn && !player.eliminated && (
												<div className="h-5 w-5 bg-bianco rounded-full"></div>
											)}
											<h3
												className={`${player.uid === user.uid ? "text-xl" : "text-lg"}`}
											>
												{player.name} {player.eliminated && "(eliminato)"}
											</h3>
										</div>
									);
								})}

								<p>Tempo rimasto: {timeLeft} secondi</p>
								{room.turn === user.uid ? (
									<div className="flex flex-col gap-2">
										<input
											type="text"
											className="text-nero p-1"
											placeholder="È il tuo turno, scrivi qui"
											value={word}
											onChange={(e) => setWord(e.currentTarget.value)}
										/>
										<button
											onClick={() => sendWord()}
											className="bg-bluIntenso"
										>
											Invia
										</button>
									</div>
								) : (
									<p>
										Aspetta il tuo turno, ora è il turno di{" "}
										{room.players.find((e) => e.uid === room.turn).name}
									</p>
								)}
								<div className="mt-5">
									<p>Parole già utilizzate</p>
									{room.usedWords.map((word) => (
										<div className="flex items-center gap-2">
											<h3>
												{word.word} - {word.type}
											</h3>
										</div>
									))}
								</div>
							</div>
						)}
					</div>
				) : (
					<div className="grid md:grid-cols-3 items-center justify-items-center h-[75dvh] w-[100vw]">
						<div className="md:justify-self-end">
							<LoadButton
								handleClick={handleCreateRoom}
								head={"Crea una stanza"}
							/>
						</div>

						<div className="bg-bianco h-96 w-[1px] md:block hidden"></div>
						<div className="flex flex-col gap-5 md:justify-self-start">
							<input
								type="text"
								className="text-bianco border bg-background placeholder:text-bianco p-2"
								placeholder="Inserisci il codice "
								value={inputRoom}
								onChange={(e) => setInputRoom(e.currentTarget.value)}
							/>

							<LoadButton
								handleClick={handleJoinRoom}
								head={"Unisciti a una stanza"}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default Prefissi;
