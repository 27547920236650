import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import { Progetti } from "./Pages/Progetti/Progetti";
import Contatti from "./Pages/Contatti/Contatti";
import "./index.css";
import Prefissi from "./Pages/Games/Prefissi/Prefissi";
import Register from "./Pages/SignIn/Register";
import { auth, db } from "./firebase";
import useUserStore from "./stores/userStore";
import { collection, doc, getDoc } from "firebase/firestore";
import { p, path } from "framer-motion/client";
import Games from "./Pages/Games/Games";
import Login from "./Pages/SignIn/Login";
import { Toaster } from "sonner";

function App() {
    const { user, setUser, isLogged, setIsLogged } = useUserStore();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (utente) => {
            if (utente) {
                const docSnap = await getDoc(doc(db, "users", utente.uid));
                if (docSnap.exists()) {
                    setUser({ uid: utente.uid, ...docSnap.data() });
                    setIsLogged(true);
                    console.log({ uid: utente.uid, ...docSnap.data() });
                }
            } else {
                setUser(null);
                setIsLogged(false);
            }
        });
    
        return () => {
            unsubscribe(); // Rimuove il listener
        };
    }, [setUser, setIsLogged]);

    const routing = [
        {
            path: "/",
            element: <Home />,
            permanent: true,
        },
        {
            path: "/progetti",
            element: <Progetti />,
            permanent: true,
        },
        {
            path: "/contatti",
            element: <Contatti />,
            permanent: true,
        },
        {
            path: "/games",
            element: <Login />,
            permanent: false,
            logged: false,
        },
        {
            path: "/games",
            element: <Games />,
            permanent: false,
            logged: true,
        },
        {
            path: "/games/prefissi",
            element: <Prefissi />,
            permanent: false,
            logged: true,
        },
        {
            path: "/signin",
            element: <Login />,
            permanent: false,
            logged: false,
        },
        {
            path: "/signup",
            element: <Register />,
            permanent: false,
            logged: false,
        },
    ];

    return (
        <div className="App">
            <Toaster  />
            <div className="fixed top-4 left-4">
                <img
                    className="w-40"
                    src={require("./assets/File_Png/Webinal_Web_Bianco.png")}
                    alt=""
                />
            </div>
            <Suspense fallback={null}>
                <BrowserRouter>
                    <Routes>
                        {routing.map((route) => {
                            if (route.permanent) {
                                return(<Route
                                    path={route.path}
                                    element={route.element}
                                />)
                            } else {
                                if (route.logged) {
                                    if (isLogged) {
                                        return (
                                            <Route
                                                path={route.path}
                                                element={route.element}
                                            />
                                        );
                                    }
                                } else {
                                    if (!isLogged) {
                                        return (
                                            <Route
                                                path={route.path}
                                                element={route.element}
                                            />
                                        );
                                    }
                                }
                            }
                            return null;
                        })}
                    </Routes>
                </BrowserRouter>
            </Suspense>
        </div>
    );
}

export default App;
