import { motion } from "framer-motion";
import { FiCheckCircle } from "react-icons/fi";
import { Fragment, useEffect, useRef, useState } from "react";

import BackgroundImage from "../../assets/File_Sfumatura/Sfumatura.png";

const TerminalContact = () => {
	const containerRef = useRef(null);
	const inputRef = useRef(null);

	return (
		<section
			style={{
				backgroundImage: `url(${BackgroundImage})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
			}}
			className="px-4 py-12 min-h-screen flex items-center justify-center"
		>
			<div
				ref={containerRef}
				onClick={() => {
					inputRef.current?.focus();
				}}
				className="h-96 bg-[#020617]/70 backdrop-blur rounded-lg w-full max-w-3xl mx-auto overflow-y-scroll shadow-xl cursor-text font-mono"
			>
				<TerminalHeader />
				<TerminalBody inputRef={inputRef} containerRef={containerRef} />
			</div>
		</section>
	);
};

const TerminalHeader = () => {
	return (
		<div className="w-full p-3 bg-[#0f172a] flex items-center gap-1 sticky top-0">
			<div className="w-3 h-3 rounded-full bg-[#ef4444]" />
			<div className="w-3 h-3 rounded-full bg-[#eab308]" />
			<div className="w-3 h-3 rounded-full bg-[#22c55e]" />
			<span className="text-sm text-[#e2e8f0] font-semibold absolute left-[50%] -translate-x-[50%]">
				info@webinal.it
			</span>
		</div>
	);
};

const TerminalBody = ({ containerRef, inputRef }) => {
	const [focused, setFocused] = useState(false);
	const [text, setText] = useState("");

	const [questions, setQuestions] = useState(QUESTIONS);

	const curQuestion = questions.find((q) => !q.complete);

	const handleSubmitLine = (value) => {
		if (curQuestion) {
			setQuestions((pv) =>
				pv.map((q) => {
					if (q.key === curQuestion.key) {
						return {
							...q,
							complete: true,
							value,
						};
					}
					return q;
				})
			);
		}
	};

	return (
		<div className="p-2 text-[#f1f5f9] text-lg">
			<InitialText />
			<PreviousQuestions questions={questions} />
			<CurrentQuestion curQuestion={curQuestion} />
			{curQuestion ? (
				<CurLine
					text={text}
					focused={focused}
					setText={setText}
					setFocused={setFocused}
					inputRef={inputRef}
					command={curQuestion?.key || ""}
					handleSubmitLine={handleSubmitLine}
					containerRef={containerRef}
				/>
			) : (
				<Summary questions={questions} setQuestions={setQuestions} />
			)}
		</div>
	);
};

const InitialText = () => {
	return (
		<>
			<p>Ciao! Siamo felici di essere in contatto! 🔗</p>
			<p className="whitespace-nowrap overflow-hidden font-light">
				------------------------------------------------------------------------
			</p>
		</>
	);
};

const PreviousQuestions = ({ questions }) => {
	return (
		<>
			{questions.map((q, i) => {
				if (q.complete) {
					return (
						<Fragment key={i}>
							<p>
								{q.text || ""}
								{q.postfix && (
									<span className="text-[#c4b5fd]">{q.postfix}</span>
								)}
							</p>
							<p className="text-[#6ee7b7]">
								<FiCheckCircle className="inline-block mr-2" />
								<span>{q.value}</span>
							</p>
						</Fragment>
					);
				}
				return <Fragment key={i}></Fragment>;
			})}
		</>
	);
};

const CurrentQuestion = ({ curQuestion }) => {
	if (!curQuestion) return <></>;

	return (
		<p>
			{curQuestion.text || ""}
			{curQuestion.postfix && (
				<span className="text-[#c4b5fd]">{curQuestion.postfix}</span>
			)}
		</p>
	);
};

const Summary = ({ questions, setQuestions }) => {
	const [complete, setComplete] = useState(false);

	const handleReset = () => {
		setQuestions((pv) => pv.map((q) => ({ ...q, value: "", complete: false })));
	};

	const handleSend = () => {
		const formData = questions.reduce((acc, val) => {
			return { ...acc, [val.key]: val.value };
		}, {});

		// Send this data to your server or whatever :)
		console.log(formData);

		setComplete(true);
	};

	return (
		<>
			<p>Perfetto! Riassumendo abbiamo:</p>
			{questions.map((q) => {
				return (
					<p key={q.key}>
						<span className="text-[#93c5fd]">{q.key}:</span> {q.value}
					</p>
				);
			})}
			<p>Tutto giusto?</p>
			{complete ? (
				<p className="text-[#6ee7b7]">
					<FiCheckCircle className="inline-block mr-2" />
					<span>Inviata! Ti risponderemo al più presto 😎</span>
				</p>
			) : (
				<div className="flex gap-2 mt-2">
					<button
						onClick={handleReset}
						className="px-3 py-1 text-base text-nero hover:opacity-90 transition-opacity rounded bg-[#f1f5f9] text-black"
					>
						Ricomincia
					</button>
					<button
						onClick={handleSend}
						className="px-3 py-1 text-base hover:opacity-90 transition-opacity rounded bg-[#6366f1] text-white"
					>
						Invia!
					</button>
				</div>
			)}
		</>
	);
};

const CurLine = ({
	text,
	focused,
	setText,
	setFocused,
	inputRef,
	command,
	handleSubmitLine,
	containerRef,
}) => {
	const scrollToBottom = () => {
		if (containerRef.current) {
			containerRef.current.scrollTop = containerRef.current.scrollHeight;
		}
	};

	const onSubmit = (e) => {
		e.preventDefault();
		handleSubmitLine(text);
		setText("");
		setTimeout(() => {
			scrollToBottom();
		}, 0);
	};

	const onChange = (e) => {
		setText(e.target.value);
		scrollToBottom();
	};

	useEffect(() => {
		return () => setFocused(false);
	}, []);

	return (
		<>
			<form onSubmit={onSubmit}>
				<input
					ref={inputRef}
					onChange={onChange}
					value={text}
					type="text"
					className="sr-only"
					autoComplete="off"
					onFocus={() => setFocused(true)}
					onBlur={() => setFocused(false)}
				/>
			</form>
			<p>
				<span className="text-emerald-400">➜</span>{" "}
				<span className="text-cyan-300">~</span>{" "}
				{command && <span className="opacity-50">Inserisci {command}: </span>}
				{text}
				{focused && (
					<motion.span
						animate={{ opacity: [1, 1, 0, 0] }}
						transition={{
							repeat: Infinity,
							duration: 1,
							ease: "linear",
							times: [0, 0.5, 0.5, 1],
						}}
						className="inline-block w-2 h-5 bg-[#94a3b8] translate-y-1 ml-0.5"
					/>
				)}
			</p>
		</>
	);
};

export default TerminalContact;

const QUESTIONS = [
	{
		key: "email",
		text: "Per cominciare, qual è ",
		postfix: "la tua email?",
		complete: false,
		value: "",
	},
	{
		key: "name",
		text: "Fantstico! E ",
		postfix: "il tuo nome?",
		complete: false,
		value: "",
	},
	{
		key: "description",
		text: "Ultima cosa, ",
		postfix: "Come possiamo aiutarti?",
		complete: false,
		value: "",
	},
];
