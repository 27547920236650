import React, { useEffect, useState } from "react";
import Entry from "../../Components/Molecules/Entry/Entry";
import Uno from "../../assets/logo/Uno";
import { use } from "framer-motion/client";
import { motion } from "framer-motion";
import BackgroundImage from "../../assets/File_Sfumatura/Sfumatura.png";

function Home() {
	const [entry, setEntry] = useState(true);
	const [hover, setHover] = useState(-1);
	const [hasToMove, setHasToMove] = useState(false);

		const [punte, setPunte] = useState([
		{
			X: "0%",
			Y: "0%",
			rotation: 0,
			originalX: "0%",
			originalY: "0%",
			hoverX: "0%",
			hoverY: "10%",
			color: "fill-viola",
		},
		{
			X: "54%",
			Y: "-59%",
			rotation: -72,
			originalX: "54%",
			originalY: "-59%",
			hoverX: "74%", // 64% + 10%
			hoverY: "-59%", // -49% - 10%
			color: "fill-bluIntenso",
		},
		{
			X: "-54%",
			Y: "-59%",
			rotation: 72,
			originalX: "-54%",
			originalY: "-59%",
			hoverX: "-74%", // -64% - 10%
			hoverY: "-59%", // -49% - 10%
			color: "fill-bluProfondo",
		},
		{
			X: "-33%",
			Y: "-156%",
			rotation: 144,
			originalX: "-33%",
			originalY: "-156%",
			hoverX: "-53%", // -43% - 10%
			hoverY: "-176%", // -166% - 10%
			color: "fill-viola",
		},
		{
			X: "33%",
			Y: "-156%",
			rotation: -144,
			originalX: "33%",
			originalY: "-156%",
			hoverX: "53%", // 43% + 10%
			hoverY: "-166%", // -166% - 10%
			color: "fill-ghiaccio",
		},
	]);

	const menu = [
		{
			title: "Progetti",
			link: "/progetti",
		},
		{
			title: "Chi siamo",
			link: "/chisiamo",
		},
		{
			title: "Contatti",
			link: "/contatti",
		},
		{
			title: "Servizi",
			link: "/servizi",
		},
		{
			title: "Games",
			link: "/games",
		},
	];

	const handleMouseEnter = (index) => {
		setPunte((prevPunte) =>
			prevPunte.map((punta, i) => {
				if (i === index) {
					return { ...punta, X: punta.hoverX, Y: punta.hoverY };
				}
				return punta;
			})
		);
		setHover(index);
	};

	const handleMouseLeave = (index) => {
		setPunte((prevPunte) =>
			prevPunte.map((punta, i) => {
				if (i === index) {
					return { ...punta, X: punta.originalX, Y: punta.originalY };
				}
				return punta;
			})
		);
		setHover(-1);
	};

	const handleMove = () => {
		setHasToMove(!hasToMove);
		setTimeout(() => {
			handleMove();
		}, 700);
	};

	useEffect(() => {
		if (entry) return;
		setTimeout(() => {
			handleMove();
		}, 1000);
	}, [entry]);

	const GlowButton = ({ onclick }) => {
		return (
			<motion.button
				onClick={(e) => {
					e.stopPropagation();
					onclick();
				}}
				className="text-white font-medium px-3 py-2 rounded-md overflow-hidden relative transition-transform "
				whileHover={{ scale: 1.1 }}
				whileTap={{ scale: 0.95 }}
				animate={{
					scale: [1, 1.1, 0.9, 1], // Aggiunge un effetto di "bumping"
					transition: {
						duration: 0.9,
						repeat: Infinity,
						repeatType: "loop",
						ease: "easeInOut",
						repeatDelay: 10, // Aggiungi una pausa di 2 secondi tra ogni ciclo
					},
				}}
			>
				<nobr className="relative z-10">Fai un regalo</nobr>
				<div className="bg-rosso absolute z-0 inset-0 w-[400%]"></div>
			</motion.button>
		);
	};

	return (
		<div className="">
			{entry ? (
				<Entry isVisible={entry} setIsVisible={setEntry} />
			) : (
				<div className="flex  w-full h-[100vh] justify-center items-center  bg-background">
					<div className="h-[50%] w-[50%] flex justify-center items-center relative">
						{punte.map((punta, index) => (
							<motion.div
								key={index}
								style={{
									transform: `translateX(${punta.X}) translateY(${punta.Y}) rotate(${hover === index ? punta.rotation + 180 : punta.rotation}deg)`,
									transition: "all 500ms ease", // Aggiunge l'animazione
									//scale: `${
									//hasToMove && index === 3 && hover === -1 ? 1.1 : 1
									//}`,
								}}
								className={`bottom-0 absolute cursor-pointer`}
								onMouseEnter={() => handleMouseEnter(index)}
								onMouseLeave={() => handleMouseLeave(index)}
								onClick={() => {
									window.location.href = menu[index].link;
								}}
							>
								<Uno
									w={20}
									className={`${
										hover === index ? "fill-viola" : "fill-bianco"
									} transition-colors duration-300 border-4  shadow-lg shadow-[#000]`}
								/>
							</motion.div>
						))}
						<div className="absolute bottom-0 -translate-y-[870%]">
							{hover > -1 && (
								<p className="text-2xl font-semibold text-bianco">
									{menu[hover].title}
								</p>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default Home;
