import { create } from "zustand";
import { persist } from "zustand/middleware";

const useGameStore = create(
    persist(
        (set) => ({
            room: {id:null},
            isLogged: false,
            setRoom: (value) => set({ room: value }),
            setIsLogged: (value) => set({ isLogged: value }),
        }),
        {
            name: "game-store", // Nome univoco per il persist store
            getStorage: () => localStorage, // Specifica lo storage da utilizzare
        }
    )
);

export default useGameStore;
