import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { FiCheck, FiLoader, FiX } from "react-icons/fi";

const LoadButton = ({ head, handleClick }) => {
    return (
        <div className="grid place-content-center p-2 border h-full ">
            <LoadAndErrorButton head={head} handleClick={handleClick}  />
        </div>
    );
};

const LoadAndErrorButton = ({ head, handleClick }) => {
    const [variant, setVariant] = useState("neutral");

    const classNames =
        variant === "neutral"
            ? ""
            : variant === "error"
              ? "bg-viola"
              : variant === "success"
                ? "bg-ghiaccio"
                : "bg-indigo-300 pointer-events-none";

   

    return (
        <motion.button
            disabled={variant !== "neutral"}
            onClick={handleClick}
            className={`relative rounded-md px-4 py-2 font-medium text-white transition-all ${classNames}`}
        >
            <motion.span
                animate={{
                    y: variant === "neutral" ? 0 : 6,
                    opacity: variant === "neutral" ? 1 : 0,
                }}
                className="inline-block"
            >
                {head}
            </motion.span>
            <IconOverlay Icon={FiLoader} visible={variant === "loading"} spin />
            <IconOverlay Icon={FiX} visible={variant === "error"} />
            <IconOverlay Icon={FiCheck} visible={variant === "success"} />
        </motion.button>
    );
};

const IconOverlay = ({ Icon, visible, spin = false }) => {
    return (
        <AnimatePresence>
            {visible && (
                <motion.div
                    initial={{
                        y: -12,
                        opacity: 0,
                    }}
                    animate={{
                        y: 0,
                        opacity: 1,
                    }}
                    exit={{
                        y: 12,
                        opacity: 0,
                    }}
                    className="absolute inset-0 grid place-content-center"
                >
                    <Icon
                        className={`text-xl duration-300 ${spin && "animate-spin"}`}
                    />
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default LoadButton;
