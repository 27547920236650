import { create } from "zustand";
import {  createJSONStorage, persist } from "zustand/middleware";


const useUserStore = create(
    persist(
        (set) => ({
            user: null,
            isLogged: false,
            setUser: (value) => set({ user: value }),
            setIsLogged: (value) => set({ isLogged: value }),
        }),
        {
            name: 'user-storage',
            storage: createJSONStorage(() =>
                typeof window !== "undefined" && window.localStorage
                    ? localStorage
                    : {
                          getItem: () => null,
                          setItem: () => {},
                          removeItem: () => {},
                      }
            ),
        }
    )
);

export default useUserStore;