import EncryptButton from "../../Atoms/Buttons/EncryptButton";
import React, { useEffect, useState } from "react";
import BackgroundImage from "../../../assets/File_Sfumatura/Sfumatura.png";

export default function Entry({ isVisible, setIsVisible, theme }) {
	//window.scrollTo(0, 0);
	const [fadedown, setFadedown] = useState(false);

	useEffect(() => {
		var canvas = document.querySelector("#scene"),
			ctx = canvas.getContext("2d"),
			particles = [],
			amount = 0,
			mouse = { x: 0, y: 0 },
			radius = 2;

		var  colorsLight= ["#934d98", "#263068", "#84cfed", "#2b4f9e"];
		var colorsDark = ["#fff", "#fff", "#fff", "#fff"];

		var copy = document.querySelector("#copy");

		var ww = (canvas.width = window.innerWidth);
		var wh = (canvas.height = window.innerHeight);

		class Particle {
			constructor(x, y) {
				this.x = Math.random() * ww;
				this.y = Math.random() * wh;
				this.dest = {
					x: x,
					y: y,
				};
				this.r = (Math.random() * ww) / 400 + 2;
				this.vx = (Math.random() - 0.5) * 2;
				this.vy = (Math.random() - 0.5) * 2;
				this.accX = 0;
				this.accY = 0;
				this.friction = Math.random() * 0.06 + 0.9;
				theme === "dark"
					? (this.color = colorsDark[Math.floor(Math.random() * 4)])
					: (this.color = Math.floor(Math.random() * 4) > 2 ? colorsLight[Math.floor(Math.random() * 4)] :"#ddd");
			}
			render() {
				this.accX = (this.dest.x - this.x) / 100;
				this.accY = (this.dest.y - this.y) / 100;
				this.vx += this.accX;
				this.vy += this.accY;
				this.vx *= this.friction;
				this.vy *= this.friction;

				this.x += this.vx;
				this.y += this.vy;

				ctx.fillStyle = this.color;
				ctx.font = "bold " + ww / 60 + "px IBM Plex Mono";

				ctx.fillText(">", this.x, this.y);

				var a = this.x - mouse.x;
				var b = this.y - mouse.y;

				var distance = Math.sqrt(a * a + b * b);
				if (distance < radius * 70) {
					this.accX = (this.x - mouse.x) / 100;
					this.accY = (this.y - mouse.y) / 100;
					this.vx += this.accX;
					this.vy += this.accY;
				}
			}
		}

		function onMouseMove(e) {
			const topscroll = window.pageYOffset;

			if (topscroll < 50) {
				mouse.x = e.clientX;
				mouse.y = e.clientY + window.pageYOffset;
			}
		}

		function onTouchMove(e) {
			if (e.touches.length > 0) {
				mouse.x = e.touches[0].clientX;
				mouse.y = e.touches[0].clientY;
			}
		}

		function onTouchEnd(e) {
			mouse.x = -9999;
			mouse.y = -9999;
		}

		function initScene() {
			ww = canvas.width = window.innerWidth;
			wh = canvas.height = window.innerHeight;

			//ctx.clearRect(0, 0, canvas.width, canvas.height);

			if (ww > 640) {
				ctx.font = "bold " + ww / 6 + "px sans-serif";
			} else {
				ctx.font = "bold " + ww / 8 + "px sans-serif";
			}

			ctx.textAlign = "center";
			ctx.fillText(copy.value, ww / 2, wh / 2);

			var data = ctx.getImageData(0, 0, ww, wh).data;
			//ctx.clearRect(0, 0, canvas.width, canvas.height);
			ctx.globalCompositeOperation = "screen";

			particles = [];
			for (var i = 0; i < ww; i += Math.round(ww / 150)) {
				for (var j = 0; j < wh; j += Math.round(ww / 150)) {
					if (data[(i + j * ww) * 4 + 3] > 50) {
						particles.push(new Particle(i, j));
					}
				}
			}
			amount = particles.length;
		}

		function onMouseClick() {
			radius++;
			if (radius === 4) {
				radius = 1;
			}
		}

		function render(a) {
			requestAnimationFrame(render);
			ctx.clearRect(0, 0, canvas.width, canvas.height);
			for (var i = 0; i < amount; i++) {
				particles[i].render();
			}
		}

		copy.addEventListener("keyup", initScene);
		//window.addEventListener("resize", initScene);
		window.addEventListener("mousemove", onMouseMove);
		//window.addEventListener("touchmove", onTouchMove);
		//window.addEventListener("click", onMouseClick);
		//window.addEventListener("touchend", onTouchEnd);
		initScene();
		requestAnimationFrame(render);

		/*
        
		window.addEventListener("scroll", () => {
			const topscroll = window.pageYOffset;
			const totAltezza = window.innerHeight;
            
			if (topscroll > 20 && ww > 640) {
				mouse.x = window.innerWidth / 2;
				mouse.y = window.innerHeight / 2 - 30;
				radius = (topscroll * 20) / totAltezza;
			} else {
				mouse.x = 9999;
				mouse.y = 9999;
				radius = 2;
			}

			const name = document.getElementById("name");
			const home = document.getElementById("home");
			const faster = document.getElementById("wedoitfaster");
			const tt_srv = document.getElementById("tt_servizi");
			const gradient = document.getElementById("gradient");

			if (topscroll > 250) {
				faster.classList.add("animate__fadeOutDown");
			} else {
				faster.classList.remove("animate__fadeOutDown");
				faster.classList.remove("hidden");
			}

			if (topscroll < 100) {
				tt_srv.classList.add("hidden");
				setFadedown(false);
			}

			if (100 <= topscroll && topscroll < 400) {
				tt_srv.classList.remove("hidden");
				if (!fadedown) {
					tt_srv.classList.add("animate__fadeInDown");
					setFadedown(true);
				}
			}

			if (window.innerWidth > 640) {
				if (topscroll > 470) {
					name.style.opacity = "0%";
					home.style.opacity = "100%";
					gradient.classList.remove("hidden");
					gradient.classList.add("animate__fadeInUp");
					gradient.classList.remove("animate__fadeOutUp");
					faster.classList.add("hidden");
				} else {
					home.style.opacity = "0%";
					name.style.opacity = "100%";
					gradient.classList.add("animate__fadeOutUp");
				}
			} else {
				if (topscroll > 20) {
					name.style.opacity = "0%";
					home.style.opacity = "100%";
					
					faster.classList.add("hidden");
				} else {
					home.style.opacity = "0%";
					name.style.opacity = "100%";
				}
			}
		});
        */

		document.getElementById("enter").addEventListener("click", () => {
			mouse.x = window.innerWidth / 2;
			mouse.y = window.innerHeight / 2 + 50;
			radius = 1;

			for (let i = 1; i < 50; i += 0.3) {
				setTimeout(() => {
					mouse.x = window.innerWidth / 2;
					mouse.y = window.innerHeight / 2 + 70;
					radius = i;
				}, i * 50); // Usa i come ritardo per creare l'effetto progressivo
				setTimeout(() => {
					setIsVisible(false);
				}, 1500);
			}

			// Imposta il raggio a 2 dopo che il ciclo è completato
		});
	}, [window.innerHeight]);

	return (
		<div className="w-[100vw] h-[100lvh] relative overflow-hidden bg-background">
			<div
				id="name"
				className="absolute left-1/2 top-1/2 w-[100vw] -translate-x-1/2 -translate-y-1/2 opacity-100 transition-all duration-700"
			>
				<div className="">
					<canvas id="scene"></canvas>
				</div>
				<input
					id="copy"
					type="text"
					value="Webinal"
					className="input_Home hidden"
					onChange={() => {
						"";
					}}
				/>

				<div className="absolute bottom-[20rem] left-1/2 -translate-x-1/2">
					<EncryptButton id="enter" />
				</div>

				<span className="scroll-btn bottom-12">
					<a href="#/">
						<span className="mouse">
							<span></span>
						</span>
					</a>
				</span>

				<svg xmlns="http://www.w3.org/2000/svg" version="1.1">
					<defs>
						<filter id="gooey">
							<feGaussianBlur
								in="SourceGraphic"
								stdDeviation="5"
								result="blur"
							/>
							<feColorMatrix
								in="blur"
								type="matrix"
								values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
								result="highContrastGraphic"
							/>
							<feComposite
								in="SourceGraphic"
								in2="highContrastGraphic"
								operator="atop"
							/>
						</filter>
					</defs>
				</svg>
			</div>
			<div id="home" className="opacity-0 transition-all duration-700"></div>
		</div>
	);
}

/*
<p
				id="wedoitfaster"
				className="text-white cursor-pointer border-white border-solid border-2 px-4 py-2 z-10 bottom-48 text-xl opacity-0 transition-all duration-[3000ms]"
				onClick={() => {window.location.href = "/home"}}
			>
				Entra
			</p>




			<button id="gooey-button" onClick={()=> window.location.href = '/home'}>
				Entra
				<span class="bubbles">
					<span class="bubble"></span>
					<span class="bubble"></span>
					<span class="bubble"></span>
					<span class="bubble"></span>
					<span class="bubble"></span>
					<span class="bubble"></span>
					<span class="bubble"></span>
					<span class="bubble"></span>
					<span class="bubble"></span>
					<span class="bubble"></span>
				</span>
			</button>
*/
