import React, { useState } from "react";
import { motion } from "framer-motion";
import { FiArrowUpRight, FiStar } from "react-icons/fi";
import { auth, db } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

const Register = () => {
    return (
        <section className="grid min-h-screen grid-cols-1 bg-background md:grid-cols-[1fr,_400px] lg:grid-cols-[1fr,_600px]">
            <Logo />
            <Form />
            <SupplementalContent />
        </section>
    );
};
export default Register;

const Form = () => {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError("Le password non corrispondono.");
            return;
        }
        try {
            const utente = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            );
            console.log(utente);
            await setDoc(doc(db, "users", utente.user.uid), {
                uid: utente.user.uid,
                email,
                name,
            });
        window.location.href = "/";
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <motion.div
            initial="initial"
            whileInView="animate"
            transition={{
                staggerChildren: 0.05,
            }}
            viewport={{ once: true }}
            className="flex text-bianco items-center justify-center pb-4 pt-20 md:py-20"
        >
            <div className="mx-auto my-auto max-w-lg px-4 md:pr-0">
                <motion.h1
                    variants={primaryVariants}
                    className="mb-2 text-center text-4xl font-semibold"
                >
                    Crea il tuo account
                </motion.h1>

                <form onSubmit={handleSubmit} className="w-full">
                    <motion.div
                        variants={primaryVariants}
                        className="mb-2 w-full"
                    >
                        <label
                            htmlFor="email-input"
                            className="mb-1 inline-block text-sm font-medium"
                        >
                            Email<span className="text-red-600">*</span>
                        </label>
                        <input
                            id="email-input"
                            type="email"
                            placeholder="Inserisci la tua email"
                            className="w-full rounded border-[1px] border-slate-300 px-2.5 py-1.5 focus:outline-indigo-600 text-nero"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </motion.div>
                    <motion.div
                        variants={primaryVariants}
                        className="mb-2 w-full"
                    >
                        <label
                            htmlFor="username-input"
                            className="mb-1 inline-block text-sm font-medium"
                        >
                            Username<span className="text-red-600">*</span>
                        </label>
                        <input
                            id="username-input"
                            type="text"
                            placeholder="Inserisci un nome utente"
                            className="w-full rounded border-[1px] border-slate-300 px-2.5 py-1.5 focus:outline-indigo-600 text-nero"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </motion.div>

                    <motion.div
                        variants={primaryVariants}
                        className="mb-2 w-full"
                    >
                        <label
                            htmlFor="password-input"
                            className="mb-1 inline-block text-sm font-medium"
                        >
                            Password<span className="text-red-600">*</span>
                        </label>
                        <input
                            id="password-input"
                            type="password"
                            placeholder="Inserisci la tua password"
                            className="w-full rounded border-[1px] border-slate-300 px-2.5 py-1.5 focus:outline-indigo-600 text-nero"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </motion.div>

                    <motion.div
                        variants={primaryVariants}
                        className="mb-2 w-full"
                    >
                        <label
                            htmlFor="confirm-password-input"
                            className="mb-1 inline-block text-sm font-medium"
                        >
                            Ripeti Password
                            <span className="text-red-600">*</span>
                        </label>
                        <input
                            id="confirm-password-input"
                            type="password"
                            placeholder="Ripeti la tua password"
                            className="w-full rounded border-[1px] border-slate-300 px-2.5 py-1.5 focus:outline-indigo-600 text-nero"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </motion.div>

                    {error && <p className="text-red-600">{error}</p>}

                    <motion.button
                        type="submit"
                        variants={primaryVariants}
                        className="mt-4 w-full rounded bg-indigo-600 py-2 text-white"
                    >
                        Registrati
                    </motion.button>
                </form>
            </div>
        </motion.div>
    );
};

const SupplementalContent = () => {
    return (
        <div className="group sticky top-4 m-4 h-80 overflow-hidden rounded-3xl rounded-tl-[4rem] bg-slate-950 md:h-[calc(100vh_-_2rem)]">
            <img
                alt="An example image"
                src="/imgs/abstract/18.jpg"
                className="h-full w-full bg-white object-cover transition-all duration-500 group-hover:scale-105 group-hover:opacity-50"
            />

            <div className="absolute right-2 top-4 z-10">
                <FiArrowUpRight className="rotate-45 text-6xl text-indigo-200 opacity-0 transition-all duration-500 group-hover:rotate-0 group-hover:opacity-100" />
            </div>

            <motion.div
                initial="initial"
                whileInView="animate"
                transition={{
                    staggerChildren: 0.05,
                }}
                viewport={{ once: true }}
                className="absolute inset-0 flex flex-col items-start justify-end bg-gradient-to-t from-slate-950/90 to-slate-950/0 p-8"
            >
                <motion.h2
                    className="mb-2 text-3xl font-semibold leading-[1.25] text-white lg:text-4xl"
                    variants={primaryVariants}
                >
                    Connecting Designers
                    <br />
                    with Opportunities
                </motion.h2>
                <motion.p
                    variants={primaryVariants}
                    className="mb-6 max-w-md text-sm text-slate-300"
                >
                    Bloop is the home of makers, making amazing things, and
                    getting paid. Find your dream job with us.
                </motion.p>
                <div className="flex items-center gap-4">
                    <div className="flex items-center">
                        <motion.img
                            variants={avatarVariants}
                            className="h-8 w-8 rounded-full border-[1px] border-slate-100 object-cover shadow-inner"
                            alt="A placeholder testimonial image"
                            src="/imgs/head-shots/1.jpg"
                        />
                        <motion.img
                            variants={avatarVariants}
                            className="-ml-4 h-8 w-8 rounded-full border-[1px] border-slate-100 object-cover shadow-inner"
                            alt="A placeholder testimonial image"
                            src="/imgs/head-shots/2.jpg"
                        />
                        <motion.img
                            variants={avatarVariants}
                            className="-ml-4 h-8 w-8 rounded-full border-[1px] border-slate-100 object-cover shadow-inner"
                            alt="A placeholder testimonial image"
                            src="/imgs/head-shots/3.jpg"
                        />
                        <motion.img
                            variants={avatarVariants}
                            className="-ml-4 h-8 w-8 rounded-full border-[1px] border-slate-100 object-cover shadow-inner"
                            alt="A placeholder testimonial image"
                            src="/imgs/head-shots/4.jpg"
                        />
                        <motion.img
                            variants={avatarVariants}
                            className="-ml-4 h-8 w-8 rounded-full border-[1px] border-slate-100 object-cover shadow-inner"
                            alt="A placeholder testimonial image"
                            src="/imgs/head-shots/6.jpg"
                        />
                    </div>
                    <div>
                        <motion.div
                            variants={primaryVariants}
                            className="flex gap-0.5"
                        >
                            <FiStar className="fill-yellow-300 text-sm text-yellow-300" />
                            <FiStar className="fill-yellow-300 text-sm text-yellow-300" />
                            <FiStar className="fill-yellow-300 text-sm text-yellow-300" />
                            <FiStar className="fill-yellow-300 text-sm text-yellow-300" />
                            <FiStar className="fill-yellow-300 text-sm text-yellow-300" />
                            <span className="ml-2 text-sm text-white">5.0</span>
                        </motion.div>
                        <motion.p
                            variants={primaryVariants}
                            className="text-xs text-slate-300"
                        >
                            from over 100,000 reviews
                        </motion.p>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

const Logo = () => {
    // Temp logo from https://logoipsum.com/
    return (
        <svg
            width="50"
            height="39"
            viewBox="0 0 50 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute left-[50%] top-4 -translate-x-[50%] fill-slate-950 md:left-4 md:-translate-x-0"
        >
            <path
                d="M16.4992 2H37.5808L22.0816 24.9729H1L16.4992 2Z"
                stopColor="#000000"
            ></path>
            <path
                d="M17.4224 27.102L11.4192 36H33.5008L49 13.0271H32.7024L23.2064 27.102H17.4224Z"
                stopColor="#000000"
            ></path>
        </svg>
    );
};

const primaryVariants = {
    initial: {
        y: 25,
        opacity: 0,
    },
    animate: {
        y: 0,
        opacity: 1,
    },
};

const avatarVariants = {
    initial: {
        x: 10,
        opacity: 0,
    },
    animate: {
        x: 0,
        opacity: 1,
    },
};
